/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');


html,
body {
    height: 100%;
    font-family: Poppins;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.content {
    width: 100%;
    max-width: 1440px;
    padding: 140px 100px;
    margin: auto;
    font-family: Poppins;

}

.h1 {
    font-family: Poppins;
    font-size: 43px;
    font-style: normal;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: 0em;
    text-align: left;
    color: #011120;

}

/* NavBar Css */

.navBar {
    height: 64px;
    background: white;
    position: fixed;
    width: 1440px;
    width: 100%;
    margin: 0 auto;
    /* box-shadow: 0 10px 15px #000; */
    left: 0;
    z-index: 10;
}

.navItem {
    color: #526B82;
    float: right;
}

.navItem:link {
    text-decoration: none;
}

.navItem:hover {
    color: #0078E5;
}

/* _______________ */

.typography {

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    display: fixed;
    color: #526B82;
}



@media (max-width: 880px) {

    .typography {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        text-align: center;
        display: fixed;
        color: #526B82;
    }
}

@media (max-width: 500px) {
    .content {
        font-family: Poppins;
        padding-top: 100px;
        padding-bottom: 100px;
        padding-right: 30px;
        padding-left: 30px;
        width: 100%;
    }
}

@media (max-width: 393px) {
    .content {
        font-family: Poppins;
        padding-top: 100px;
        padding-bottom: 100px;
        padding-right: 20px;
        padding-left: 20px;
        width: 100%;
    }

    .h1 {
        font-family: Poppins;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 45px;
        letter-spacing: 0em;
        text-align: left;
        color: #011120;
    }
}